// Font family
$font-base: 		'Roboto', sans-serif;
$font-hedding:	'Barlow Semi Condensed', sans-serif;

// Colors
$body-color:		#999999;
$primary:				#719367;
$secondary:		  #89a481;
$white:					#ffffff;
$gray-1: 				#f6f6f6; 	// bg light
$gray-2: 				#dfdfdf;
$gray-3: 				#999999; 	// body-text
$gray-4: 				#707173;
$gray-5: 				#3a4957;
$gray-6: 				#040404;
$gray-7: 				#bbbbbb;
$gray-8: 				#333333; 	// Hedding color
$gray-9: 				#18191c; 	// bg color
$black:  				#000000;
$success:				#0abb0a;
$danger:				#f52626;

$border-color: 	#e7e7e7;

// For button and input border radius
$border-radius: 50px;
