/*****************************
  Counter
*****************************/
.counter {
	.timer {
		font-size: 60px;
    line-height: 60px;
		font-weight: 600;
	}
}
