/*

Template: Xberg - Multipurpose Blog HTML Template
Version: 1.0.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/
/*==============================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
    - Accordion
    - Button
		- Counter
		- Date time Picker
		- Newsletter
		- Owl carousel
		- Range Slider
		- Select
		- Tabs
 :: Header
   - Header default
   - Header transparent
   - Header light
 :: Banner
   - Banner home
   - Banner shop
   - Banner location
   - Slider product
 :: Layout
   - Section Title
   - Page title
   - About box
 :: Sidebar
 :: Blog
   - Blog post
   - Blog sidebar
   - Blog detail
 :: Shop
   - Product
   - Product Detail
    - Shop Sidebar
    - Checkout
    - Cart
 :: Not Found
 :: Footer

======================================
[ End table content ]
======================================*/
/*****************************
    Typography
*****************************/
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #999999;
  overflow-x: hidden; }

a {
  outline: medium none !important;
  color: #719367;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important; }
  a:focus {
    color: #719367;
    text-decoration: none !important; }
  a:hover {
    color: #719367;
    text-decoration: none !important; }

input {
  outline: medium none !important;
  color: #719367; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: normal;
  color: #333333;
  margin-top: 0px;
  text-transform: capitalize; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit; }

label {
  font-weight: normal; }

h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700; }

h2 {
  font-size: 30px;
  font-weight: 700; }

h3 {
  font-size: 28px;
  font-weight: 700; }

h4 {
  font-size: 26px;
  font-weight: 600; }

h5 {
  font-size: 24px;
  font-weight: 500; }

h6 {
  font-size: 18px;
  font-weight: 500; }

p {
  font-weight: normal;
  line-height: 26px; }

*::-moz-selection {
  background: #719367;
  color: #ffffff;
  text-shadow: none; }

::-moz-selection {
  background: #719367;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background: #719367;
  color: #ffffff;
  text-shadow: none; }

@media (min-width: 1200px) {
  .container {
    max-width: 1170px; } }

/* container-fluid */
.container-fluid.container-space {
  padding: 0 100px; }

/* form-control */
.form-control {
  border: 1px solid #e7e7e7;
  border-radius: 0px;
  padding: 10px 20px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  background-clip: inherit !important;
  background: #f6f6f6;
  border-radius: 50px; }
  .form-control:focus {
    box-shadow: none;
    border-color: #719367; }

.form-control::-moz-placeholder {
  color: #333333; }

.form-control::-ms-input-placeholder {
  color: #333333; }

.form-control::-webkit-input-placeholder {
  color: #333333; }

input.form-control {
  height: 50px; }

/* Inner Header Shape */
.cls-1 {
  fill: #fff;
  fill-rule: evenodd; }

.cls-1-dark {
  fill: #18191c;
  fill-rule: evenodd; }

/* Dark Form */
.dark-form h6 {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500; }

.dark-form .form-control {
  background-color: #bbbbbb;
  border-color: #bbbbbb;
  color: rgba(255, 255, 255, 0.4);
  font-weight: normal; }

.dark-form .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4); }

.dark-form .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4); }

.dark-form .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4); }

.dark-form .select2-container--default .select2-selection--single {
  background-color: #bbbbbb; }

.dark-form .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.4);
  font-weight: normal; }

.dark-form .select2-container--default .select2-selection--single .select2-selection__arrow:before {
  color: rgba(255, 255, 255, 0.4); }

.dark-page .select2-container--open .select2-dropdown {
  background: #bbbbbb;
  border-color: #bbbbbb; }

.dark-page .select2-container--default .select2-results > .select2-results__options {
  color: rgba(255, 255, 255, 0.4); }

.dark-page .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.4); }

.dark-page .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.4); }

.dark-page .select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: #000000;
  border-color: #000000;
  color: rgba(255, 255, 255, 0.4); }

/* custom-file */
.custom-file {
  height: 50px; }
  .custom-file .custom-file-input {
    height: 50px;
    color: #333333;
    border-radius: 50px;
    border-color: #e7e7e7; }
    .custom-file .custom-file-input:focus {
      box-shadow: none;
      border-color: #719367; }
  .custom-file .custom-file-label {
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    border-color: #e7e7e7;
    padding: 15px 90px 15px 20px;
    border-radius: 50px; }
    .custom-file .custom-file-label:after {
      height: 48px;
      padding: 14px 25px; }

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
  border-color: #719367; }

/* input-group-prepend */
.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  width: 50px;
  background: transparent;
  border-color: #e7e7e7;
  box-shadow: none;
  justify-content: center; }

/* checkbox */
.custom-control-input:checked ~ .custom-control-label:before {
  background: #719367;
  border-color: #719367; }

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  background: transparent;
  border-color: transparent; }

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #e7e7e7; }

.custom-control-label:before {
  top: 2px; }

.custom-control-label:after {
  top: 2px; }

.custom-checkbox .custom-control-label:before {
  border-radius: 50px;
  border: 2px solid #dfdfdf; }

/* border  */
.border {
  border-color: #e7e7e7 !important; }

.border-top {
  border-top-color: #e7e7e7 !important; }

.border-left {
  border-left-color: #e7e7e7 !important; }

.border-right {
  border-right-color: #e7e7e7 !important; }

.border-bottom {
  border-bottom-color: #e7e7e7 !important; }

/* badge  */
.badge {
  border-radius: 50px; }
  .badge + .badge {
    margin-left: 6px; }

.badge-md {
  padding: 5px 8px;
  font-size: 13px;
  font-weight: normal; }

.badge-primary {
  background: #719367; }

/* back-to-to */
.back-to-top a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: 50px;
  height: 40px;
  margin: 0px;
  color: #ffffff;
  font-size: 18px;
  background: #719367;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  z-index: 999; }
  .back-to-top a:hover {
    color: #ffffff; }
  .back-to-top a:focus {
    color: #ffffff; }

/* pagination */
.pagination .page-item .page-link {
  padding: 14px 28px;
  color: #999999;
  border-color: #e7e7e7;
  margin: 0 4px; }
  .pagination .page-item .page-link span {
    display: block; }
  .pagination .page-item .page-link:focus {
    box-shadow: none; }

.pagination .page-item.active .page-link {
  background: #719367;
  border-color: #719367;
  color: #ffffff; }

.pagination .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* blockquote */
.blockquote {
  font-size: 16px;
  font-style: italic;
  display: block;
  padding: 50px;
  background: #f6f6f6;
  border: 1px solid #e7e7e7;
  position: relative; }
  .blockquote i {
    z-index: 0;
    font-size: 30px;
    line-height: 30px; }

iframe {
  width: 100%; }

/* table */
.table {
  border-spacing: 0 1px;
  font-size: 16px;
  font-weight: 600; }
  .table td {
    padding: 14px 20px; }
  .table th {
    padding: 14px 20px; }

.table-bordered td {
  border-color: #e7e7e7; }

.table-bordered th {
  border-color: #e7e7e7; }

.table-striped tbody tr:nth-of-type(odd) {
  background: #040404; }
  .table-striped tbody tr:nth-of-type(odd) td {
    color: #ffffff; }

.table-striped .avatar {
  margin-right: 15px; }

.table-striped tr td {
  color: #ffffff; }

.table td,
.table th {
  vertical-align: middle; }

.table-skew {
  padding: 0 30px; }

@media (max-width: 767px) {
  .table td,
  .table th {
    white-space: nowrap; } }

/*****************************
    Helper Classes
*****************************/
.text-primary {
  color: #719367 !important; }

.text-secondary {
  color: #89a481 !important; }

.text-white {
  color: #ffffff; }

.text-dark {
  color: #000000 !important; }

.text-light {
  color: #999999 !important; }

.text-success {
  color: #0abb0a !important; }

.text-danger {
  color: #f52626 !important; }

.text-primary-soft {
  color: rgba(113, 147, 103, 0.5) !important; }

.text-stroke-white {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  display: block; }

.text-stroke-primary {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #719367;
  display: block; }

.text-stroke-dark {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #18191c;
  display: block; }

.skew-reverse {
  display: inline-block; }

.bg-primary {
  background: #719367 !important; }

.bg-dark {
  background: #000000 !important; }

.bg-dark-02 {
  background: #bbbbbb !important; }

.bg-secondary {
  background: #89a481 !important; }

.bg-facebook {
  background: #5d82d1 !important; }

.bg-pinterest {
  background: #e13138 !important; }

.bg-linkedin {
  background: #238cc8 !important; }

.bg-white-soft {
  background: rgba(255, 255, 255, 0.1); }

.bg-dark-soft {
  background: rgba(0, 0, 0, 0.1); }

.space-ptb {
  padding: 90px 0; }

.space-pt {
  padding: 90px 0 0; }

.space-pb {
  padding: 0 0 90px; }

.h-100vh {
  height: 100vh !important; }

/* Sticky column */
.is-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100px; }

hr {
  border-top-color: rgba(255, 255, 255, 0.1); }

hr.hr-dark {
  border-top-color: rgba(0, 0, 0, 0.1); }

.footer-border-top {
  border: 1px solid #18191c; }

.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%; }

.bg-holder-bottom {
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%; }

/*form-group*/
.form-group {
  margin-bottom: 1rem; }

.bg-overlay-black-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-10:before {
    background: rgba(0, 0, 0, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-20:before {
    background: rgba(0, 0, 0, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-30 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-30:before {
    background: rgba(0, 0, 0, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-40 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-40:before {
    background: rgba(0, 0, 0, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-50:before {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-60 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-60:before {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-70:before {
    background: rgba(0, 0, 0, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-80 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-80:before {
    background: rgba(0, 0, 0, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-90:before {
    background: rgba(0, 0, 0, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-10:before {
    background: rgba(255, 255, 255, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-20:before {
    background: rgba(255, 255, 255, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-30 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-30:before {
    background: rgba(255, 255, 255, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-40 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-40:before {
    background: rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-50:before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-60 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-60:before {
    background: rgba(255, 255, 255, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-70:before {
    background: rgba(255, 255, 255, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-80 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-80:before {
    background: rgba(255, 255, 255, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-white-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-90:before {
    background: rgba(255, 255, 255, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-theme-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-20:before {
    background: rgba(113, 147, 103, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-theme-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-50:before {
    background: rgba(113, 147, 103, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-theme-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-70:before {
    background: rgba(113, 147, 103, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-theme-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-theme-90:before {
    background: rgba(113, 147, 103, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-20 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-20:before {
    background: rgba(137, 164, 129, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-50 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-50:before {
    background: rgba(137, 164, 129, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-70 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-70:before {
    background: rgba(137, 164, 129, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-90 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-90:before {
    background: rgba(137, 164, 129, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-left-100 {
  position: relative; }
  .bg-overlay-left-100:before {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    background-color: #719367;
    width: 100%;
    height: 100%; }

.bg-overlay-right-100 {
  position: relative; }
  .bg-overlay-right-100:before {
    position: absolute;
    content: "";
    top: 0;
    right: -100%;
    background-color: #89a481;
    width: 100%;
    height: 100%; }

.bg-overlay-left {
  position: relative; }
  .bg-overlay-left:before {
    position: absolute;
    content: "";
    top: -20px;
    left: 0;
    background-color: #89a481;
    width: 46%;
    height: calc(100% - -20px);
    transform-origin: 0 0; }

.half-overlay-left {
  position: relative; }
  .half-overlay-left:before {
    position: absolute;
    content: "";
    top: -40px;
    background-color: #719367;
    width: 106%;
    height: 100%;
    right: -5%; }
  .half-overlay-left:after {
    position: absolute;
    content: "";
    top: -40px;
    background-color: #719367;
    width: 100%;
    height: 100%;
    right: 100%;
    z-index: -1; }

.half-overlay-right {
  position: relative; }
  .half-overlay-right:before {
    position: absolute;
    content: "";
    top: 0;
    background-color: #89a481;
    width: 106%;
    left: -5%;
    height: 100%;
    z-index: -1; }
  .half-overlay-right:after {
    position: absolute;
    content: "";
    top: 0;
    background-color: #89a481;
    width: 100%;
    left: 100%;
    height: 100%;
    z-index: -1; }

/*our-since*/
.our-since {
  padding: 30px;
  width: 42%;
  background: #89a481;
  position: absolute;
  top: 0; }

.media {
  align-items: center; }
  .media .media-body span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700; }
  .media .media-body h6 {
    font-size: 16px; }

/* Margin negative */
.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -5rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -5rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -5rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -5rem !important; }

.m-n7 {
  margin: -7rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -7rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important; }

.m-n8 {
  margin: -10rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -10rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -10rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -10rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -10rem !important; }

.m-n9 {
  margin: -12rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -12rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -12rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -12rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -12rem !important; }

.m-n10 {
  margin: -14rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -14rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -14rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -14rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -14rem !important; }

.avatar {
  width: 50px;
  height: 50px;
  display: inline-block; }

.avatar.avatar-sm {
  width: 30px;
  height: 30px; }

.avatar.avatar-md {
  width: 40px;
  height: 40px; }

.avatar.avatar-lg {
  width: 75px;
  height: auto; }

.avatar.avatar-xl {
  width: 80px;
  height: 80px; }

.avatar.avatar-xll {
  width: 100px;
  height: 100px; }

.avatar.avatar-xlll {
  width: 120px;
  height: 120px; }

.avatar.avatar-xllll {
  width: 160px;
  height: 160px; }

.avatar.avatar-xlllll {
  width: 180px;
  height: 180px; }

.font-sm {
  font-size: 13px; }

.font-md {
  font-size: 16px; }

.font-lg {
  font-size: 17px; }

.font-xl {
  font-size: 21px; }

.font-xxl {
  font-size: 30px; }

.font-xxxl {
  font-size: 40px;
  line-height: 40px; }

.line-h-sm {
  line-height: 13px; }

.line-h-md {
  line-height: 16px; }

.line-h-lg {
  line-height: 17px; }

.line-h-xl {
  line-height: 21px; }

.line-h-xxl {
  line-height: 30px; }

.line-h-xxxl {
  line-height: 40px; }

.b-radius-none {
  border-radius: 0 !important; }

.b-radius-left-none {
  border-radius: 0 3px 3px 0 !important; }

.b-radius-right-none {
  border-radius: 3px 0 0 3px !important; }

.sticky-top {
  z-index: 8; }

.z-index-1 {
  z-index: 1; }

.z-index-9 {
  z-index: 9; }

.z-index-99 {
  z-index: 99; }

.z-index-999 {
  z-index: 999; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.bottom-0 {
  bottom: 0; }

.top-0 {
  top: 0; }

.overflw-x-hidden {
  overflow-x: hidden; }

.overflw-y-hidden {
  overflow-y: hidden; }

.position-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center; }

.fill-primary {
  fill: #719367;
  fill-rule: evenodd;
  opacity: 0.3; }

.fill-light {
  fill: #f6f6f6;
  fill-rule: evenodd; }

.fill-secondary {
  fill: #89a481;
  fill-rule: evenodd;
  opacity: 0.6; }

.half-section-prl {
  padding: 0px 250px 0px 100px; }

.p-lg-6 {
  padding: 5rem !important; }

.px-lg-6 {
  padding-left: 5rem !important;
  padding-right: 5rem !important; }

.py-lg-6 {
  padding-bottom: 5rem !important;
  padding-top: 5rem !important; }

.pt-lg-6 {
  padding-top: 5rem !important; }

.pb-lg-6 {
  padding-bottom: 5rem !important; }

.pl-lg-6 {
  padding-left: 5rem !important; }

.pr-lg-6 {
  padding-right: 5rem !important; }

.list ul {
  padding: 0px; }
  .list ul li {
    list-style: none;
    position: relative;
    margin-bottom: 12px; }

.author-text {
  border-left: 4px solid #719367 !important;
  padding: 5px 0px 0px 20px; }

.social ul li {
  margin-right: 20px;
  display: inline-block; }
  .social ul li:last-child {
    margin-right: 0px; }

/*blog-overlay*/
.blog-overlay {
  position: relative;
  text-align: left;
  z-index: 2; }

.blog-overlay:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  background: linear-gradient(0deg, black 5%, rgba(0, 0, 0, 0.09) 100%);
  content: "";
  left: 0;
  right: 0;
  z-index: 1; }

.blog-overlay .blog-name {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  padding: 15px 15px 0; }

.blog-overlay .blog-name a.tag {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600; }

.blog-overlay .blog-name h5 a {
  font-size: 24px; }

.blog-overlay .blog-name h5:hover a {
  color: #ffffff; }

.blog-overlay.white-bg:before, .blog-overlay.dark-theme-bg:before, .blog-overlay.theme-bg:before {
  display: none; }

.blog-overlay .blog-image {
  overflow: hidden;
  position: relative; }

/*blog-overlay-01*/
.blog-overlay-02 {
  position: relative;
  text-align: left;
  z-index: 2; }

.blog-overlay-02:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  left: 0;
  right: 0;
  z-index: 1; }

.blog-overlay-02 .blog-name {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 90%;
  margin: 0 auto;
  width: 90%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px; }

.blog-overlay-02 .blog-name a.tag {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600; }

.blog-overlay-02 .blog-name h5 a {
  font-weight: 500;
  font-size: 24px; }

.blog-overlay-02.white-bg:before, .blog-overlay-02.dark-theme-bg:before, .blog-overlay-02.theme-bg:before {
  display: none; }

.blog-overlay-02 .blog-image {
  overflow: hidden;
  position: relative; }

.blog-post.blog-overlay-02 .blog-post-footer a {
  color: #18191c; }

.blog-post.blog-overlay-02 .blog-post-author span {
  color: #18191c; }

/*blog-overlay-01*/
.blog-overlay-01 {
  position: relative;
  text-align: left;
  z-index: 2; }

.blog-overlay-01 .blog-name {
  padding-left: 30px;
  position: absolute;
  bottom: -30px;
  right: 0;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  width: 60%;
  text-align: right;
  padding: 15px 25px; }

.blog-overlay-01 .blog-name a.tag {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600; }

.blog-overlay-01 .blog-name h5 a {
  font-weight: 500;
  font-size: 24px; }

.blog-overlay-01.white-bg:before, .blog-overlay-01.dark-theme-bg:before, .blog-overlay-01.theme-bg:before {
  display: none; }

.blog-overlay-01 .blog-image {
  overflow: hidden;
  position: relative;
  padding-right: 20px; }

.blog-overlay-03 {
  text-align: center;
  position: relative; }
  .blog-overlay-03 .blog-name h5 {
    font-size: 20px; }
    .blog-overlay-03 .blog-name h5 a {
      text-align: left;
      background: #ffffff;
      display: inline-block;
      position: relative;
      padding: 15px 20px;
      top: -28px;
      width: 85%;
      margin: 0 auto;
      align-items: center;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); }
      .blog-overlay-03 .blog-name h5 a img {
        width: 40px;
        height: 40px;
        margin-right: 15px; }

.swiper-container.editor .blog-post .blog-image img {
  height: auto; }

.swiper-container.editor .blog-post .blog-name {
  padding-left: 0px; }
  .swiper-container.editor .blog-post .blog-name h1 {
    font-size: 16px;
    line-height: 26px;
    padding: 0px 15px; }
  .swiper-container.editor .blog-post .blog-name .blog-post-footer {
    padding-bottom: 0px;
    padding-top: 10px; }
  .swiper-container.editor .blog-post .blog-name a.tag {
    padding: 8px 15px; }

.advertise {
  padding: 90px 30px;
  position: relative;
  background-size: cover;
  display: flex;
  justify-content: center; }

.xberg-item-info h1 {
  font-size: 56px;
  margin-left: -120px;
  font-weight: 400;
  padding-right: 150px;
  background: #ffffff;
  padding: 20px 30px; }

.navigation.post-navigation .blog-post-nav-media img {
  border-radius: 50%;
  width: 85px;
  height: 85px;
  object-fit: cover; }

.navigation.post-navigation .nav-previous .nav-left {
  width: 70%; }
  .navigation.post-navigation .nav-previous .nav-left .pagi-text {
    padding-bottom: 0px;
    text-decoration: none; }
  .navigation.post-navigation .nav-previous .nav-left .nav-title {
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 16px;
    font-weight: 500; }

.navigation.post-navigation .nav-next .nav-right {
  width: 70%; }
  .navigation.post-navigation .nav-next .nav-right .pagi-text {
    padding-bottom: 0px;
    text-decoration: none; }
  .navigation.post-navigation .nav-next .nav-right .nav-title {
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 16px;
    font-weight: 500; }

.blog-post-share-box .badges a {
  border: 1px solid #719367;
  padding: 8px 10px;
  color: #719367 !important; }

.blog-post-slider {
  position: relative; }
  .blog-post-slider .owl-dots {
    position: absolute;
    bottom: 10px; }

.grid-post {
  padding-bottom: 50px; }
  .grid-post ul {
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap; }
    .grid-post ul li {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      list-style: none; }

/*blog-entry-audio*/
.blog-post-image .blog-entry-audio.audio-video {
  padding: 200px 20px;
  background: url(../images/travel/blog/07.jpg) no-repeat;
  background-position: center center;
  background-size: cover; }

.banner.blog-single-04 .blog-image {
  height: 100%;
  max-width: 100%; }

@media (max-width: 1599px) {
  .bmi-form {
    overflow-x: hidden;
    padding-top: 40px;
    margin-top: -40px; }
  .blog-overlay-03 .blog-name a {
    padding: 15px 30px; } }

@media (max-width: 991px) {
  .bmi-form .col-lg-6.half-overlay-right,
  .bmi-form .col-lg-6.half-overlay-left {
    padding: 0 30px; }
  .bmi-form .half-overlay-left:after,
  .bmi-form .half-overlay-right:after {
    content: none; }
  .bmi-form {
    padding-top: 0px;
    margin-top: 40px; }
  .xberg-item-info h1 {
    font-size: 28px;
    line-height: 36px; }
  .banner-03 .swiper-slide {
    height: 600px !important; }
  .blog-overlay-01 .blog-image {
    padding-right: 0px !important; }
  .blog-overlay-01 .blog-name {
    position: inherit;
    width: 100%;
    bottom: 0px; }
  .banner.blog-single-04 .blog-image {
    height: 500px !important; } }

@media (max-width: 767px) {
  .xberg-item-info h1 {
    font-size: 26px;
    line-height: 30px;
    margin-left: -30px; }
  .banner-03 .swiper-slide {
    height: 550px !important; }
  .navigation.post-navigation .nav-next .nav-right, .navigation.post-navigation .nav-previous .nav-left {
    width: 100%; }
  .blog-post .blog-content {
    padding: 0px 0px 10px 0px !important; }
  .mt-n9,
  .my-n9 {
    margin-top: 0rem !important; }
  .ml-n6,
  .mx-n6 {
    margin-left: 0rem !important; } }

@media (max-width: 575px) {
  /*our-since*/
  .our-since {
    padding: 20px;
    width: 70%; }
  .widget-content .social {
    display: block; }
  .grid-post ul li {
    width: 100%; }
  .blockquote {
    padding: 30px; }
  .banner.blog-single-04 .blog-image {
    height: 350px !important; } }

/*****************************
  Shortcodes
*****************************/
/*****************************
  Accordion
*****************************/
.accordion .accordion-item {
  border: none;
  padding: 0 12px;
  margin-bottom: 20px; }
  .accordion .accordion-item .accordion-header {
    background: none;
    border: 1px solid #e7e7e7;
    padding: 0; }
    .accordion .accordion-item .accordion-header:before {
      border-bottom: 1px solid #e7e7e7; }
    .accordion .accordion-item .accordion-header .accordion-button {
      position: relative;
      font-size: 22px;
      color: #719367;
      padding: 17px 30px 17px 30px;
      width: 100%;
      text-align: left;
      border: 1px solid #e7e7e7; }
      .accordion .accordion-item .accordion-header .accordion-button:focus {
        box-shadow: inherit; }
    .accordion .accordion-item .accordion-header .accordion-button.collapsed {
      color: #333333; }
  .accordion .accordion-item .accordion-collapse {
    border: 1px solid #e7e7e7; }
    .accordion .accordion-item .accordion-collapse .accordion-body {
      padding: 30px;
      border: 1px solid #e7e7e7; }

/* accordion-02 */
.accordion.accordion-02 .accordion-item {
  margin-bottom: 10px; }
  .accordion.accordion-02 .accordion-item .accordion-header {
    margin-bottom: 0;
    border: none; }
    .accordion.accordion-02 .accordion-item .accordion-header .accordion-button {
      font-size: 18px;
      padding: 10px 0 10px 30px;
      border: none;
      font-weight: 500; }
      .accordion.accordion-02 .accordion-item .accordion-header .accordion-button:after {
        background-size: 1rem; }
      .accordion.accordion-02 .accordion-item .accordion-header .accordion-button:focus {
        box-shadow: none; }
      .accordion.accordion-02 .accordion-item .accordion-header .accordion-button:before {
        line-height: 12px;
        font-size: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        content: "\f068";
        font-family: "Font Awesome 5 Free";
        border: 1px solid #18191c;
        width: 15px;
        height: 15px;
        line-height: 12px;
        border-radius: 50%;
        z-index: 9;
        text-align: center; }
      .accordion.accordion-02 .accordion-item .accordion-header .accordion-button:after {
        display: none; }
  .accordion.accordion-02 .accordion-item .accordion-collapse {
    border: none; }
    .accordion.accordion-02 .accordion-item .accordion-collapse .accordion-body {
      padding: 0;
      border: none; }

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #719367; }

.accordion .accordion-button.collapsed:after {
  display: none; }

.accordion .accordion-button:not(.collapsed):after {
  display: none; }

/*****************************
  Newsletter
*****************************/
.newsletter {
  padding: 50px 30px;
  position: relative; }
  .newsletter .form-control {
    padding-right: 180px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5); }
  .newsletter .btn {
    position: absolute;
    right: 0;
    line-height: 20px;
    top: 0;
    padding: 14px 30px; }
  .newsletter .custom-control-label {
    margin-top: -5px;
    margin-left: 10px; }

/*****************************
  Counter
*****************************/
.counter .timer {
  font-size: 60px;
  line-height: 60px;
  font-weight: 600; }

/*****************************
  Tabs
*****************************/
.nav-tabs {
  border: none; }
  .nav-tabs .nav-item {
    margin-bottom: 10px;
    margin-right: 10px; }
    .nav-tabs .nav-item:last-child {
      margin-right: 0; }
    .nav-tabs .nav-item .nav-link {
      border: none;
      font-size: 16px;
      padding: 12px 30px;
      border-radius: 0;
      font-weight: 600;
      background: #89a481;
      color: #ffffff; }
      .nav-tabs .nav-item .nav-link span {
        display: block; }
    .nav-tabs .nav-item .nav-link.active {
      background: #719367;
      color: #ffffff; }

/*****************************
  Owl carousel
*****************************/
.owl-carousel .owl-item img {
  width: auto;
  display: inherit; }

.owl-carousel .owl-nav {
  cursor: pointer;
  z-index: 999;
  width: 100%; }
  .owl-carousel .owl-nav .owl-prev {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    left: 10px;
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
    display: inline-block;
    margin: 14px 2px 0; }
  .owl-carousel .owl-nav .owl-next {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    right: 10px;
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
    display: inline-block;
    margin: 14px 2px 0; }
  .owl-carousel .owl-nav i {
    font-size: 16px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    color: #719367;
    background: #89a481;
    border: 0px solid #e7e7e7;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    .owl-carousel .owl-nav i:before {
      display: block; }
    .owl-carousel .owl-nav i:hover {
      background: #719367;
      color: #89a481; }

.owl-carousel .owl-controls .owl-dot {
  margin: 0;
  display: inline-block; }

.owl-carousel .owl-dots {
  display: inline-block;
  margin-top: 20px;
  text-indent: inherit;
  width: 100%;
  cursor: pointer;
  text-align: center; }
  .owl-carousel .owl-dots .owl-dot span {
    background: #ddd;
    display: inline-block;
    width: 12px;
    min-height: 12px;
    margin-top: 5px;
    border-radius: 100%;
    transition: all 0.5s ease-in-out;
    cursor: pointer; }
  .owl-carousel .owl-dots .owl-dot:hover span {
    background: #719367; }
  .owl-carousel .owl-dots .owl-dot.active span {
    background: #719367; }

.owl-carousel .owl-dot {
  display: inline-block;
  margin: 14px 2px 0; }

.owl-nav-bottom .owl-nav {
  bottom: 0;
  top: inherit; }
  .owl-nav-bottom .owl-nav .owl-next {
    right: 45%;
    bottom: -70px;
    top: inherit; }
  .owl-nav-bottom .owl-nav .owl-prev {
    left: 45%;
    bottom: -70px;
    top: inherit; }

.owl-nav-top-left .owl-nav {
  bottom: 0;
  top: inherit; }
  .owl-nav-top-left .owl-nav .owl-next {
    right: 0;
    top: -80px;
    transform: inherit; }
  .owl-nav-top-left .owl-nav .owl-prev {
    left: inherit;
    right: 50px;
    top: -80px;
    transform: inherit; }
  .owl-nav-top-left .owl-nav i {
    border: 1px solid transparent; }
    .owl-nav-top-left .owl-nav i:hover {
      border-color: #e7e7e7; }

.owl-nav-top-right .owl-nav {
  top: 0;
  bottom: inherit; }
  .owl-nav-top-right .owl-nav .owl-next {
    right: 20px;
    top: 20px;
    bottom: inherit;
    transform: inherit; }
  .owl-nav-top-right .owl-nav .owl-prev {
    left: inherit;
    right: 80px;
    top: 20px;
    bottom: inherit;
    transform: inherit; }

.owl-dots-bottom-center .owl-dots {
  bottom: 30px !important;
  position: absolute; }
  .owl-dots-bottom-center .owl-dots .owl-dot span {
    background: #ffffff; }

.owl-dots-bottom-right .owl-dots {
  bottom: 30px !important;
  position: absolute;
  right: 30px;
  width: auto; }
  .owl-dots-bottom-right .owl-dots .owl-dot span {
    background: #ffffff; }

.owl-dots-bottom-left .owl-dots {
  position: absolute;
  left: 0;
  width: auto;
  margin-top: 0; }
  .owl-dots-bottom-left .owl-dots .owl-dot span {
    background: #ffffff; }

/* Client Logo */
.our-clients .owl-carousel .owl-stage {
  display: flex;
  align-items: center; }

.owl-carousel .owl-item .property-item {
  margin-bottom: 0; }

.carousel-control-next,
.carousel-control-prev {
  opacity: .6; }

/*****************************
  Date time Picker
*****************************/
.bootstrap-datetimepicker-widget table td.active {
  background-color: #719367; }
  .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #719367; }

.bootstrap-datetimepicker-widget table td.day {
  height: 30px;
  line-height: 30px;
  width: 40px;
  font-size: 14px; }

.bootstrap-datetimepicker-widget table th {
  font-size: 14px; }

.bootstrap-datetimepicker-widget table td span.active {
  background: #719367; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 19rem; }

/*****************************
  Button
*****************************/
.btn {
  transition: all 0.3s ease-in-out;
  text-transform: uppercase; }

button {
  outline: medium none !important;
  color: #719367; }

/* btn */
.btn {
  font-weight: 600;
  font-size: 13px;
  padding: 12px 26px;
  border-radius: 50px; }
  .btn:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
    outline: none; }
  .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    outline: none; }
  .btn:hover {
    box-shadow: none;
    outline: none; }
  .btn:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff; }
  .btn:active {
    box-shadow: none;
    outline: none;
    color: #ffffff; }

.show > .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
  color: #ffffff; }

.show > .btn-primary.dropdown-toggle {
  background: #719367;
  border-color: #719367; }

.btn-primary {
  background: #719367;
  border-color: #719367;
  color: #ffffff;
  position: relative; }
  .btn-primary:hover {
    background: #333333;
    border-color: #333333;
    color: #ffffff; }
    .btn-primary:hover:not(:disabled):not(.disabled).active {
      background: #333333;
      border-color: #333333;
      color: #ffffff; }
    .btn-primary:hover:not(:disabled):not(.disabled):active {
      background: #333333;
      border-color: #333333;
      color: #ffffff; }
  .btn-primary:focus {
    background: #333333;
    border-color: #333333;
    color: #ffffff; }
  .btn-primary:focus {
    background: #333333;
    border-color: #333333;
    color: #ffffff; }

.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #333333;
  border-color: #333333; }

.btn-secondary {
  background: #89a481;
  border-color: #89a481;
  color: #ffffff;
  position: relative; }
  .btn-secondary:hover {
    background: #78976f;
    border-color: #78976f; }
    .btn-secondary:hover:not(:disabled):not(.disabled).active {
      background: #89a481;
      border-color: #89a481; }
    .btn-secondary:hover:not(:disabled):not(.disabled):active {
      background: #89a481;
      border-color: #89a481; }
  .btn-secondary:focus {
    background: #78976f;
    border-color: #78976f; }
  .btn-secondary:focus {
    background: #78976f;
    border-color: #78976f; }

.btn-secondary:not(:disabled):not(.disabled):active:focus {
  background: #89a481;
  border-color: #89a481; }

.btn-dark {
  background: #18191c;
  border-color: #18191c; }
  .btn-dark:hover {
    background: #719367;
    border-color: #719367; }

.btn:not(:disabled):not(.disabled).active:focus {
  color: #ffffff; }

.btn i {
  padding-right: 10px; }

.btn + .btn {
  margin-left: 3px; }

.btn-link {
  padding: 0;
  color: #719367; }
  .btn-link:hover {
    color: #18191c;
    text-decoration: none;
    outline: none; }
  .btn-link:focus {
    color: #18191c;
    text-decoration: none;
    outline: none; }

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
  color: #18191c; }

.btn-white {
  background: #ffffff;
  border-color: #ffffff;
  color: #18191c; }
  .btn-white:hover {
    background: #dfdfdf;
    border-color: #dfdfdf;
    color: #18191c; }
  .btn-white:active {
    color: #18191c; }
  .btn-white:focus {
    color: #18191c; }

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
  color: #18191c; }

.btn-light:active {
  color: #18191c; }

.btn-light:focus {
  color: #18191c; }

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
  color: #18191c; }

.btn.btn-sm {
  padding: 6px 24px; }

.btn.btn-md {
  padding: 9px 26px; }

.btn.btn-lg {
  padding: 13px 30px; }

.btn.btn-xl {
  padding: 16px 40px; }

.btn-app {
  display: flex; }
  .btn-app i {
    font-size: 40px; }

.btn-outline-secondary {
  border: 2px solid #e7e7e7;
  color: #333333; }
  .btn-outline-secondary:hover {
    background: #333333;
    color: #ffffff;
    border-color: #18191c; }
  .btn-outline-secondary:focus {
    background: #18191c;
    color: #ffffff;
    border-color: #18191c; }

.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background: #18191c;
  color: #ffffff;
  border-color: #18191c; }

/* Button Outline */
.btn-outline-light {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-light:hover {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000; }
  .btn-outline-light:focus {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000; }
  .btn-outline-light:not(:disabled):not(.disabled).active {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000; }
  .btn-outline-light:not(:disabled):not(.disabled):active {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000; }

/* Button Outline */
.btn-outline-primary {
  background-color: transparent;
  color: #719367;
  border-color: #719367; }
  .btn-outline-primary:hover {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000; }
  .btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000; }
  .btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000; }

.play-video-lg {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
  background: #ffffff;
  color: #89a481;
  display: block;
  border-radius: 50%;
  text-align: center; }

.play-video-md {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 16px;
  background: #ffffff;
  color: #89a481;
  display: block;
  border-radius: 50%;
  text-align: center; }

.play-video-02 {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
  background: #719367;
  color: #ffffff;
  display: block;
  border-radius: 0;
  text-align: center; }
  .play-video-02:hover {
    color: #719367;
    background: #ffffff; }
  .play-video-02:focus {
    color: #719367;
    background: #ffffff; }

/*****************************
		Select
*****************************/
.select2-container .select2-selection--single {
  height: auto;
  outline: none; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0px; }

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border: none; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #333333;
    font-weight: normal;
    line-height: inherit;
    text-transform: capitalize; }

.bigdrop {
  min-width: 196px !important; }

.select2-results__options {
  padding: 8px 2px; }

.select2-container {
  width: 100% !important; }

.select2-container--default .select2-results__option--highlighted {
  border-radius: 0;
  transition: all 0.2s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  padding-left: 10px;
  font-weight: 600; }

input[type="radio"]:radio {
  background-color: #719367; }

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 14px;
  right: 10px;
  height: 21px;
  width: 14px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none; }
  .select2-container--default .select2-selection--single .select2-selection__arrow:before {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    color: #719367; }

.select2-container--default .select2-search--dropdown {
  padding: 0; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #e7e7e7; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f6f6f6; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(113, 147, 103, 0.1);
  color: #719367; }

.select2-dropdown {
  border: none;
  border-radius: 0px;
  z-index: 8; }

.select-border .select2-container--default .select2-selection--single {
  border: 1px solid #e7e7e7;
  height: 50px;
  padding: 15px 20px;
  border-radius: 30px; }
  .select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 16px;
    right: 20px; }

.select2-results__option[aria-selected] {
  text-transform: capitalize; }

.select2-container--default .select2-results > .select2-results__options {
  border: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #999999; }

.select2-container--open .select2-dropdown {
  background: #f6f6f6;
  padding: 7px;
  border: 1px solid #e7e7e7; }

.select2-search--dropdown .select2-search__field {
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04); }

.select2-container--default .select2-selection--single {
  height: 50px;
  padding: 15px 20px; }

.custom-control-input:radio {
  background-color: #719367; }

.newsletter .search input::placeholder {
  color: #ffffff; }

.form-check .form-check-input {
  float: inherit; }

.form-check-input:checked {
  background-color: #719367;
  border-color: #719367;
  box-shadow: inherit; }

/*****************************
	Range Slider
*****************************/
.irs--flat .irs-line {
  height: 6px; }

.irs--flat .irs-bar {
  background: #719367;
  height: 6px; }

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  background: transparent;
  color: #719367;
  font-size: 13px;
  font-weight: 600; }

.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-handle > i:first-child {
  background: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #719367;
  cursor: pointer;
  transition: all 0.3s ease-in-out; }

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  display: none; }

.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background: #719367; }

/*****************************
    Header
*****************************/
.header {
  z-index: 999;
  position: relative; }
  .header .container-fluid {
    padding: 0 60px;
    align-items: initial; }

/* Header default */
.header .is-sticky.navbar {
  background: #ffffff; }

/* topbar */
.header .topbar {
  background: #ffffff;
  padding: 40px 0;
  border-bottom: 1px solid #e7e7e7; }

.header.bg-dark .topbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5); }

.header .topbar a {
  color: #719367; }
  .header .topbar a:hover {
    color: #333333; }

.header .topbar .dropdown {
  margin-right: 20px; }
  .header .topbar .dropdown .dropdown-toggle {
    padding: 12px 0; }
    .header .topbar .dropdown .dropdown-toggle i {
      font-size: 10px; }
  .header .topbar .dropdown .dropdown-menu a {
    color: #333333; }
    .header .topbar .dropdown .dropdown-menu a:hover {
      color: #719367; }

.header .topbar .social {
  display: inline-block; }
  .header .topbar .social ul {
    margin: 0; }
    .header .topbar .social ul li {
      display: inline-block;
      padding-right: 40px; }
      .header .topbar .social ul li a {
        color: #333333; }
        .header .topbar .social ul li a:hover {
          color: #719367; }

.header .topbar .login {
  display: inline-block; }
  .header .topbar .login a {
    color: #333333; }
    .header .topbar .login a:hover {
      color: #719367; }

.topbar .dropdown .dropdown-menu {
  border-radius: 0;
  border: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px; }

.topbar .dropdown .dropdown-menu a {
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 3px; }

.topbar .dropdown .dropdown-menu a:hover {
  color: #719367; }

.navbar {
  padding: 0; }

.navbar .navbar-nav .nav-link {
  font-weight: 500;
  font-size: 17px;
  padding: 10px 20px;
  color: #333333;
  text-transform: uppercase;
  font-family: "Barlow Semi Condensed", sans-serif; }
  .navbar .navbar-nav .nav-link i {
    font-weight: bold; }
  .navbar .navbar-nav .nav-link:hover {
    color: #719367; }

.dropdown-menu {
  z-index: 9999; }

.navbar-collapse {
  position: relative; }

.navbar-light {
  color: #ffffff;
  background-color: #f6f6f6;
  border-color: #e7e7e7; }

.navbar-light .navbar-nav > li > a {
  color: #ffffff; }

.navbar-light .navbar-nav > .dropdown > a .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff; }

.navbar-brand {
  padding: 30px 0px;
  margin-right: 0; }

.navbar-brand img {
  height: 36px; }

.navbar .navbar-brand {
  color: #ffffff;
  flex: 0 0 180px; }

.header .navbar-nav > li {
  display: flex; }

.header .navbar-nav li > a {
  align-items: center;
  display: flex;
  padding: 3px 0px;
  color: #333333;
  text-transform: capitalize;
  font-size: 14px; }

.header .navbar-nav .dropdown-menu li > a {
  border-bottom: 1px solid #e7e7e7; }

.header .navbar-nav li > a:hover {
  color: #719367; }

.header .navbar-nav li > a i {
  margin-left: 5px;
  font-size: 10px; }

.header .add-listing {
  align-self: center;
  flex: 0 0 135px;
  text-align: right; }

.megamenu {
  padding: 20px 20px;
  width: 100%; }

.megamenu > div > li > ul {
  padding: 0;
  margin: 0; }

.megamenu > div > li > ul > li {
  list-style: none; }

.megamenu .card {
  outline: none; }

.megamenu .card:hover,
.megamenu .card:focus {
  outline: 1px solid #000000; }

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #18191c;
  white-space: normal; }

.megamenu > div > li > ul > li > a:hover,
.megamenu > div > li > ul > li > a:focus {
  text-decoration: none;
  color: #18191c;
  background-color: #f6f6f6; }

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: #f6f6f6; }

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed; }

.megamenu.dropdown-header {
  color: #719367;
  font-size: 18px; }

.header li > .dropdown-item:focus,
.header li > .dropdown-item:hover {
  color: #719367;
  background: none; }

.header .dropdown-item.active,
.header .dropdown-item:active,
.header .dropdown-item:focus,
.header .dropdown-item:hover {
  background: none; }

.header .dropdown-toggle::after {
  content: none; }

.header .navbar-collapse {
  align-items: inherit;
  width: auto; }

.header .navbar-collapse ul {
  padding-left: 80px; }

.header .megamenu .dropdown-item {
  padding: 0px; }

.header .navbar .dropdown-menu a.dropdown-item {
  min-width: 180px;
  font-family: "Barlow Semi Condensed", sans-serif; }

.navbar .navbar-nav .dropdown-menu li.active > a {
  padding-left: 20px !important; }

.header .navbar .dropdown > .dropdown-menu li > a:hover {
  padding-left: 20px !important; }

.header .navbar .dropdown-menu {
  padding: 0; }

.header .navbar .dropdown-menu li a i {
  margin-left: auto; }

.navbar .dropdown > .dropdown-menu li > a {
  font-size: 14px;
  padding: 10px 20px 10px 20px;
  border-radius: 3px;
  position: relative; }

.navbar .dropdown > .dropdown-menu li > a:hover, .navbar .dropdown > .dropdown-menu li.active > a {
  color: #719367;
  padding-left: 15px; }

/* Header default */
.header.default {
  background: #000000; }
  .header.default .navbar {
    padding: 10px 0; }
    .header.default .navbar .navbar-brand {
      margin-right: 0px; }
    .header.default .navbar .navbar-nav .nav-link {
      color: #000000; }
      .header.default .navbar .navbar-nav .nav-link:hover {
        color: #719367; }
    .header.default .navbar .navbar-nav .nav-item.active .nav-link {
      color: #719367; }
    .header.default .navbar .navbar-nav .dropdown-menu li.active > a {
      color: #719367;
      padding-left: 10px; }
  .header.default .topbar .search ul {
    padding-left: 0px; }
    .header.default .topbar .search ul li {
      list-style: none; }
      .header.default .topbar .search ul li i {
        font-size: 24px;
        font-weight: 900; }
  .header.default .topbar .dropdown {
    margin-right: 0px; }

/* Header center */
.header.header-center .navbar .navbar-brand {
  display: none; }

.header.header-center .is-sticky.navbar {
  justify-content: space-between !important; }

.header.header-center .is-sticky.navbar .navbar-brand {
  display: block;
  text-align: left; }

.header.header-center.bg-dark .is-sticky.navbar {
  background: #18191c !important; }

/* Header transparent */
.header.default.header-transparent {
  position: relative;
  background: transparent;
  width: 100%;
  z-index: 999; }

.header.default.header-transparent .search ul {
  padding-left: 0px;
  margin-bottom: 0px; }

.header.default.header-transparent .search ul li {
  list-style: none; }

.header.default.header-transparent .search ul li a {
  color: #719367; }

.header.default.header-transparent .social ul {
  margin-bottom: 0px; }

.header.default.header-transparent .social ul li {
  margin-right: 20px;
  display: inline-block; }

.header.default.header-transparent .social ul li a {
  color: #000000; }

.header.default.header-transparent .social ul li:hover a {
  color: #719367; }

.navbar .navbar-nav .dropdown-menu li.active > a {
  color: #719367;
  padding-left: 10px; }

.header-transparent .add-listing {
  align-self: inherit;
  flex: inherit;
  text-align: right; }

.header-transparent .login a {
  color: #ffffff; }
  .header-transparent .login a:hover {
    color: #719367; }

.header.default.bg-dark .navbar .navbar-nav .nav-link {
  color: #ffffff; }
  .header.default.bg-dark .navbar .navbar-nav .nav-link:hover {
    color: #719367; }

.header.default.bg-dark .topbar .social ul li a {
  color: #ffffff; }

.header.default.bg-dark .topbar .social ul li:hover a {
  color: #719367; }

/* Search */
.search .search-btn {
  display: block;
  height: 22px;
  line-height: 22px;
  position: relative;
  text-indent: -9999px;
  width: 14px;
  color: #719367;
  transition: color 300ms ease 0s;
  transition: 0.5s ease-in-out; }
  .search .search-btn:before {
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-size: 24px;
    font-weight: 900;
    right: 0;
    position: absolute;
    text-indent: 0;
    top: 0; }

.search .search-box button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s ease-in-out; }

.search .form-control {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border: none; }

.search.search-open .search-btn:before {
  content: "\f00d"; }

.search.search-open .search-box {
  opacity: 1 !important;
  visibility: visible !important;
  transform: rotateX(0deg); }
  .search.search-open .search-box .form-control {
    padding-right: 50px; }

.search-box {
  display: block;
  margin: 0;
  opacity: 0 !important;
  position: absolute;
  right: 0;
  top: 35px;
  transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
  visibility: hidden;
  width: 327px;
  z-index: 888;
  border-radius: 3px;
  transform: rotateX(90deg);
  transform-origin: 0 0;
  transition: all 400ms ease; }

.search-box.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(10px);
  transform: translateY(10px); }

.header .is-sticky.navbar {
  position: fixed !important;
  width: 100%;
  background: #ffffff !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 0 100px; }
  .header .is-sticky.navbar .navbar-brand {
    display: block; }
  .header .is-sticky.navbar .header-contact-info {
    display: none !important; }
  .header .is-sticky.navbar .navbar-nav .nav-link {
    padding: 10px 20px; }

.header-transparent .is-sticky.navbar {
  position: fixed !important;
  width: 100%;
  background: #18191c;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  top: 0;
  z-index: 9; }
  .header-transparent .is-sticky.navbar .header-contact-info {
    display: none !important; }
  .header-transparent .is-sticky.navbar .navbar {
    position: relative;
    top: 0px;
    right: 0px;
    left: 0;
    border-top: none !important;
    width: 100%; }

.nav-title {
  line-height: 1.6; }

@media (min-width: 992px) {
  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    left: 100%;
    transform: translate3d(-50%, 10px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important;
    border-top: 4px solid #719367; }
  .header .navbar .dropdown-menu.megamenu {
    left: 50%; }
  .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 630px; }
  .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 450px; }
  .navbar-nav .mega-menu {
    position: static; }
  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
    opacity: 1; }
  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
    left: 100%;
    right: auto;
    transform: translate3d(0, 10px, 0); }
  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
    right: 100%;
    left: auto;
    transform: translate3d(-15px, 10px, 0); }
  .navbar-nav li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1; }
  .dropdown-submenu {
    position: relative; }
  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px; }
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg); } }

.place-card-large {
  display: none !important; }

@media (max-width: 1199px) {
  .header .container {
    max-width: 96%; }
  .header.default .navbar.is-sticky {
    padding: 0 30px; } }

@media (max-width: 991px) {
  .header .is-sticky .navbar {
    top: 0;
    right: 0; }
  .header-right-bg:before {
    left: 0;
    height: 100%;
    transform: inherit; }
  .header .is-sticky.navbar {
    padding: 0 20px; }
  .header.default {
    padding: 0; }
    .header.default .navbar-brand {
      display: block; }
  .header.default .navbar-toggler {
    color: #333333;
    box-shadow: none; }
  .header.default.header-transparent .social {
    display: none; }
  .header.default .navbar {
    padding: 0px 0px !important; }
  .header.default .is-sticky.navbar {
    padding: 0px 20px !important; }
  /*header-center*/
  .header.header-center.default .topbar {
    display: none; }
  .header.header-center .navbar .navbar-brand {
    display: block;
    text-align: left; }
  .header.header-center.default .navbar {
    padding: 40px 0;
    justify-content: space-between !important; }
  /*header-dark*/
  .header.header-center.default.bg-dark .navbar-toggler {
    color: #ffffff; }
  .header.header-center.default.bg-dark .navbar .navbar-nav .nav-link {
    color: #333333; }
  .header.header-center.default.bg-dark .navbar .navbar-nav .nav-item.active .nav-link {
    color: #719367; }
  .header .navbar .navbar-nav .nav-item.active .nav-link:after,
  .header .navbar .navbar-nav .nav-item.active .nav-link:before {
    display: none; }
  .header .navbar .navbar-nav .nav-item:hover .nav-link:after,
  .header .navbar .navbar-nav .nav-item:hover .nav-link:before {
    display: none; }
  .header.default.header-transparent .search ul {
    display: none !important; }
  .header.default .navbar-brand img {
    height: 26px; } }

@media (max-width: 575px) {
  .header.default .navbar-toggler {
    padding: 0;
    height: 60px; }
  .header.default .navbar-brand {
    padding: 20px 0px; }
    .header.default .navbar-brand img {
      height: 24px; }
  .banner.slider-02 {
    padding: 0; } }

#search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  opacity: 0;
  visibility: hidden; }

#search-overlay.open {
  z-index: 9999999;
  visibility: visible;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

#search-overlay.open {
  background-color: rgba(255, 255, 255, 0.95); }

/*****************************
  		Sidebar
*****************************/
.categorize li a {
  background: #f6f6f6;
  color: #89a481;
  padding: 16px 30px;
  display: block;
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 600; }
  .categorize li a span {
    display: block; }

.categorize li a.active {
  border-left: 5px solid #719367;
  color: #719367; }

/*****************************
    Banner
*****************************/
/*****************************
    Banner
*****************************/
/*slider-02*/
.banner.slider-02 {
  position: relative;
  overflow: hidden; }

.banner.slider-02 .swiper-container {
  overflow: visible; }

.banner.slider-02 .swiper-slide.swiper-slide-active .blog-overlay:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0.09) 100%); }

.banner.slider-02 .swiper-slide .blog-overlay:before {
  background: rgba(0, 0, 0, 0.8); }

/*****************************
    Banner-03
*****************************/
.banner-03 .swiper-slide {
  height: 750px; }

/*****************************
    Banner-04
*****************************/
.banner-04 .swiper-slide {
  height: 550px; }

.banner-04 .blog-post .blog-post-footer a {
  color: #ffffff; }

.banner .icon-btn {
  display: flex;
  width: 48px;
  height: 48px;
  text-align: center;
  background: #dfdfdf;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: #89a481;
  font-size: 30px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out; }
  .banner .icon-btn:hover {
    background: #ffffff; }
  .banner .icon-btn:focus {
    outline: none;
    box-shadow: none; }

.banner .swiper-button-prev.icon-btn {
  left: 100px; }
  .banner .swiper-button-prev.icon-btn i {
    font-size: 16px; }

.banner .swiper-button-next.icon-btn {
  right: 100px; }
  .banner .swiper-button-next.icon-btn i {
    font-size: 16px; }

.swiper-button-next, .swiper-button-prev {
  z-index: 8; }

/*****************************
    Responsive
*****************************/
@media (max-width: 575px) {
  .social {
    display: none; }
  .banner.slider-05 .blog-overlay-02 .blog-name h1 {
    font-size: 24px; }
  .banner.slider-05 .blog-overlay-02 .blog-name .blog-post.blog-post-footer {
    padding-bottom: 0; } }

/*****************************
    Layout
*****************************/
/* section title */
.section-title {
  margin-bottom: 50px; }
  .section-title .sub-title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative; }
  .section-title .pre-title {
    font-size: 16px; }
  .section-title .title-shape {
    display: inline-block;
    width: 16px; }
    .section-title .title-shape:before {
      content: "";
      background: #89a481;
      height: 20px;
      width: 8px;
      left: 0;
      top: 0;
      position: absolute; }
    .section-title .title-shape:after {
      content: "";
      background: #719367;
      height: 20px;
      width: 8px;
      left: 8px;
      top: 0;
      position: absolute; }
  .section-title .dark-title .title-shape:before {
    background-color: #ffffff; }
  .section-title .theme-title .title-shape:after {
    background-color: #ffffff; }

/* About box */
.map iframe.contact-map {
  height: 620px;
  width: 100%;
  border: 0; }

.map-box iframe {
  border: 0;
  width: 100%;
  height: 200px; }

/* Load more */
.more-row {
  display: none; }

/* Dark page */
.dark-page {
  background-color: #000000; }

.dropcap {
  font-weight: 500;
  font-size: 90px;
  width: 80px;
  height: 70px;
  line-height: 70px;
  float: left;
  margin-right: 10px; }

/* Instagram */
.instagram-grid-4 {
  min-height: 920px; }

.instagram-grid-6 .instagram_profile {
  display: none; }

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: opacity .5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 9999; }

#search.open {
  opacity: 1;
  visibility: visible;
  transition: opacity .5s ease-in-out; }

#search input[type="search"] {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  color: #333333;
  background: #f6f6f6;
  font-size: 60px;
  font-weight: 300;
  text-align: center;
  border: #e7e7e7;
  border-radius: 50px;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none; }

#search .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 61px;
  margin-left: -45px; }

#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  background-color: #000000;
  color: #ffffff;
  opacity: 1;
  padding: 3px 17px;
  font-size: 27px; }

.page-title-02 {
  height: 600px; }

/*****************************
  Shop
*****************************/
/* Product */
.product {
  text-align: center; }
  .product .product-image {
    position: relative; }
    .product .product-image .product-overlay {
      opacity: 0;
      text-align: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 1;
      margin: 0 auto;
      transition: all 0.5s ease-in-out; }
      .product .product-image .product-overlay .add-to-cart a {
        display: inline-block;
        background: #719367;
        color: #ffffff;
        text-transform: capitalize;
        padding: 10px 20px;
        border-radius: 50px;
        font-weight: bold; }
        .product .product-image .product-overlay .add-to-cart a span {
          display: inline-block; }
  .product:hover .product-image .product-overlay {
    opacity: 1; }
  .product .product-description .product-title {
    margin: 20px 0px 10px; }
    .product .product-description .product-title a {
      font-size: 20px;
      font-weight: bold;
      color: #333333; }
      .product .product-description .product-title a:hover {
        color: #719367; }
  .product .product-description .product-price {
    font-size: 14px;
    font-weight: bold; }
    .product .product-description .product-price del {
      color: #999999; }
    .product .product-description .product-price ins {
      color: #89a481;
      text-decoration: none; }

/* Product Detail */
.shop-single .nav-tabs-02 .nav-item .nav-link {
  font-size: 16px; }

.shop-single .product-detail .product-price-rating .product-price {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-price-rating .product-price h4 {
    font-size: 20px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-price-rating .product-price h4 span {
      font-size: 24px;
      color: #719367;
      margin-left: 8px; }

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0; }
  .shop-single .product-detail .product-price-rating .product-rating i {
    color: #719367; }

.shop-single .product-detail .product-summary {
  margin-top: 30px; }
  .shop-single .product-detail .product-summary a {
    color: #333333;
    margin-right: 10px; }
    .shop-single .product-detail .product-summary a i {
      color: #719367;
      margin-right: 10px; }
    .shop-single .product-detail .product-summary a:hover {
      color: #719367; }

.shop-single .product-detail hr {
  margin: 30px 0; }

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-detail-meta span {
    display: block;
    margin: 10px 0; }

.shop-single .product-detail .product-detail-social {
  display: flex;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0; }
  .shop-single .product-detail .product-detail-social span {
    font-weight: 800; }
  .shop-single .product-detail .product-detail-social ul {
    padding-left: 15px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-detail-social ul li {
      display: inline-block;
      list-style-type: none;
      float: left;
      margin-right: 20px;
      font-size: 16px; }

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px; }

.form-group .product-rating {
  color: #719367; }

.slider-slick .slider-nav .slick-next:before {
  position: absolute;
  right: -15px;
  top: 50%;
  z-index: 999;
  color: #214d63;
  font-size: 30px;
  transform: translatey(-50%); }

.slider-slick .slider-nav .slick-prev:before {
  position: absolute;
  left: -15px;
  top: 50%;
  z-index: 999;
  color: #214d63;
  font-size: 30px;
  transform: translatey(-50%); }

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1; }

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1; }

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 30px;
  height: 50%;
  background: #719367;
  color: #333333;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 30px;
  height: 50%;
  background: #719367;
  color: #333333;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px; }

/* commentlist */
.commentlist {
  display: flex; }
  .commentlist .comment-content {
    border: 1px solid #e7e7e7;
    padding: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 100%; }
    .commentlist .comment-content .reviews {
      display: flex; }
      .commentlist .comment-content .reviews .rating {
        margin-left: auto; }
        .commentlist .comment-content .reviews .rating i {
          color: #719367; }

.form-check-label {
  padding-left: 20px; }

/* Shop Sidebar */
.blog-sidebar {
  padding: 20px 20px; }
  .blog-sidebar .widget {
    margin-bottom: 50px; }
    .blog-sidebar .widget .widget-title {
      margin-bottom: 10px; }
    .blog-sidebar .widget .price-filter {
      display: flex; }
      .blog-sidebar .widget .price-filter a {
        margin-left: auto; }
    .blog-sidebar .widget .custom-control-label {
      line-height: 20px; }
    .blog-sidebar .widget .form-check {
      display: block;
      font-size: 16px;
      margin-bottom: 10px; }
      .blog-sidebar .widget .form-check:last-child {
        margin-bottom: 0; }
      .blog-sidebar .widget .form-check span {
        float: right; }
    .blog-sidebar .widget .social ul li {
      display: inline-block;
      list-style-type: none;
      margin-right: 20px;
      font-size: 16px; }
    .blog-sidebar .widget .tagcloud ul li {
      margin-bottom: 10px;
      display: inline-block; }
      .blog-sidebar .widget .tagcloud ul li a {
        color: #333333;
        line-height: 32px;
        border: 1px solid #e7e7e7;
        padding: 0 16px;
        display: block; }
        .blog-sidebar .widget .tagcloud ul li a span {
          display: block; }
        .blog-sidebar .widget .tagcloud ul li a:hover {
          background: #719367;
          border: 1px solid #719367;
          color: #ffffff; }
    .blog-sidebar .widget .widget-categories ul li a {
      font-size: 14px;
      color: #333333;
      line-height: 32px;
      display: block; }
      .blog-sidebar .widget .widget-categories ul li a:hover {
        color: #719367; }

.search-field {
  padding-left: 20px;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  height: 50px;
  border-radius: 30px;
  width: 100%; }

/* Checkout */
.checkout-info {
  padding: 25px 25px 25px 120px;
  background: #f6f6f6;
  position: relative; }
  .checkout-info:before {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 25px;
    content: "\f2f6";
    font-size: 60px;
    font-family: "Font Awesome 5 Free";
    z-index: 8;
    color: #dfdfdf;
    font-weight: 600; }

.checkout-info-coupon .checkout-info:before {
  content: "\f3ff"; }

.checkout-info-coupon .checkout-coupon {
  padding: 20px;
  border: 2px dashed #e7e7e7; }

.checkout-review {
  padding: 15px 30px 30px;
  border: 1px solid #e7e7e7; }
  .checkout-review .table tr th {
    padding: 15px 0;
    font-size: 18px; }
  .checkout-review .table tr th.shipping {
    font-size: 14px; }
  .checkout-review .table tr td {
    padding: 15px 0;
    font-size: 14px; }
  .checkout-review .table tr td.amount {
    font-size: 18px; }

/* Cart */
.cart-table .table {
  border: 1px solid #e7e7e7;
  margin-bottom: 0; }
  .cart-table .table tr th {
    border: none;
    padding: 15px 10px;
    color: #333333; }
  .cart-table .table tr td {
    border: none;
    border-top: 1px solid #e7e7e7;
    padding: 15px 10px;
    color: #333333; }
    .cart-table .table tr td.product-remove {
      padding: 15px 25px;
      text-align: center; }
    .cart-table .table tr td.product-quantity .form-control {
      width: 100px; }
    .cart-table .table tr td a {
      color: #333333; }
      .cart-table .table tr td a:hover {
        color: #719367; }
    .cart-table .table tr td .form-group {
      margin-bottom: 0px; }

.cart-table .actions {
  display: flex;
  border: 1px solid #e7e7e7;
  border-top: none;
  padding: 15px 10px; }
  .cart-table .actions .coupon .input-text {
    border: 1px solid #e7e7e7;
    border-radius: 0px;
    height: 48px;
    padding: 10px 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: 500;
    color: #214d63;
    border-radius: 0px; }
  .cart-table .actions .coupon .btn {
    padding: 10px 20px; }
  .cart-table .actions .update-cart {
    margin-left: auto; }

.cart-table .table td.product-thumbnail img {
  width: 70px;
  height: auto; }

.cart-totals {
  background: #f6f6f6;
  padding: 20px; }
  .cart-totals .cart-totals-title {
    margin-bottom: 20px;
    text-align: center; }
  .cart-totals .table tr th {
    text-align: left;
    border: none;
    border-top: 1px solid #e7e7e7;
    vertical-align: top;
    background: #ffffff; }
  .cart-totals .table tr td {
    text-align: right;
    border: none;
    border-top: 1px solid #e7e7e7;
    background: #ffffff; }
  .cart-totals .table tr.shipping .form-group {
    margin-bottom: 0; }
  .cart-totals .table tr.shipping .form-check-label {
    padding-left: 0px; }
  .cart-totals .table tr.shipping p {
    margin-bottom: 0; }
  .cart-totals .table tr.order-total th {
    font-size: 18px;
    font-weight: bold; }
  .cart-totals .table tr.order-total td {
    font-size: 26px;
    font-weight: bold; }
  .cart-totals .table .cart-subtotal th {
    font-size: 18px;
    font-weight: bold;
    border: none; }
  .cart-totals .table .cart-subtotal td {
    font-size: 18px;
    font-weight: bold;
    border: none; }
  .cart-totals .checkout-button {
    width: 100%;
    text-align: center;
    padding: 15px 25px; }

.checkout label {
  margin-bottom: 10px; }

/*****************************
    Blog
*****************************/
/* Blog post */
.blog-post {
  background: #ffffff; }
  .blog-post .blog-post-categorise .blog-post-meta {
    display: inline-flex;
    padding: 14px 10px;
    position: relative;
    text-align: center;
    flex-wrap: wrap; }
    .blog-post .blog-post-categorise .blog-post-meta li {
      margin-right: 8px;
      padding-right: 15px;
      position: relative; }
      .blog-post .blog-post-categorise .blog-post-meta li:before {
        position: absolute;
        right: 0; }
      .blog-post .blog-post-categorise .blog-post-meta li:last-child {
        padding: 0px;
        margin-right: 0; }
        .blog-post .blog-post-categorise .blog-post-meta li:last-child:before {
          display: none; }
  .blog-post .blog-post-content .media {
    display: flex; }
  .blog-post .badge {
    border: 1px solid #719367;
    padding: 8px 25px 6px 25px;
    border-radius: 50px;
    font-weight: 800;
    color: #719367;
    margin-top: 20px;
    margin-bottom: 8px;
    line-height: 20px;
    text-transform: uppercase; }
    .blog-post .badge:hover {
      background: #719367;
      color: #ffffff;
      border: 1px solid #719367; }
  .blog-post .blog-content {
    padding: 0px 0px 40px 0px; }
  .blog-post .blog-post-footer {
    display: flex;
    justify-content: center;
    padding: 25px 0px; }
    .blog-post .blog-post-footer a {
      font-size: 12px;
      color: #bbbbbb; }
      .blog-post .blog-post-footer a:hover {
        color: #719367; }
        .blog-post .blog-post-footer a:hover i {
          color: #719367; }
    .blog-post .blog-post-footer i {
      padding-right: 5px;
      color: #719367; }
  .blog-post .blog-post-share-box {
    display: flex;
    justify-content: center;
    padding: 25px 0px;
    border-bottom: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7; }
    .blog-post .blog-post-share-box a {
      font-size: 12px;
      color: #bbbbbb; }
      .blog-post .blog-post-share-box a:hover {
        color: #719367; }
        .blog-post .blog-post-share-box a:hover i {
          color: #719367; }
    .blog-post .blog-post-share-box i {
      padding-right: 5px;
      color: #719367; }
  .blog-post .blog-post-share-box .badges a.btn-outline-primary:hover {
    background: #719367;
    color: #ffffff !important; }
  .blog-post .blog-post-footer .blog-post-author img {
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
    width: auto;
    display: inline-block; }
  .blog-post .blog-post-footer .blog-post-time,
  .blog-post .blog-post-author,
  .blog-post .blog-post-time,
  .blog-post .blog-detail .blog-post-comment,
  .blog-post .blog-post-share {
    margin: 0 8px; }

.widget .search {
  position: relative; }
  .widget .search input {
    padding-right: 64px;
    height: 50px;
    padding-left: 20px;
    color: #ffffff; }
  .widget .search i {
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    padding: 18px 20px;
    cursor: pointer;
    margin: 0;
    border-radius: 0 50px 50px 0px; }

.widget .search input:placeholder {
  color: #ffffff; }

.blog-post-02 .blog-content {
  padding: 30px 20px;
  margin: -50px 15px 0px 15px;
  z-index: 1;
  position: relative;
  background: #ffffff; }
  .blog-post-02 .blog-content h6 {
    font-size: 18px; }

.blog-post-03 {
  background: #f6f6f6;
  padding: 20px !important; }

.blog-post.blog-post-03.bg-img {
  min-height: 400px; }

.blog-post-03 {
  min-height: 220px;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

.blog-post-03 .blog-post-info {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  padding: 20px; }
  .blog-post-03 .blog-post-info .blog-post-title a {
    color: #ffffff; }

.blog-post-03 .blog-post-info .blog-post-category {
  background: #719367;
  padding: 2px 10px;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 10px; }
  .blog-post-03 .blog-post-info .blog-post-category a {
    color: #ffffff; }

.blog-post-03.bg-overlay-half-bottom:before {
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
  top: auto;
  height: 40%;
  -webkit-transition: all .35s;
  transition: all .35s; }

/* blog-post-04 */
.blog-post.blog-post-04 {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .blog-post.blog-post-04 .blog-post-image {
    width: 45%; }
  .blog-post.blog-post-04 .blog-content {
    padding: 25px !important;
    width: 55%; }
    .blog-post.blog-post-04 .blog-content .badge {
      margin-top: 0; }
  .blog-post.blog-post-04 .blog-post-footer {
    justify-content: start; }

/* blog-post-05 */
.blog-post.blog-overlay.blog-post-05 {
  cursor: pointer; }
  .blog-post.blog-overlay.blog-post-05:before {
    background: transparent; }
  .blog-post.blog-overlay.blog-post-05 .blog-post-footer {
    padding: 0;
    justify-content: start; }
  .blog-post.blog-overlay.blog-post-05 .blog-image {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    height: 130px; }
  .blog-post.blog-overlay.blog-post-05 .blog-name {
    transform: translate(0, -50%);
    top: 50%;
    bottom: inherit;
    padding: 35px; }
    .blog-post.blog-overlay.blog-post-05 .blog-name h5 {
      margin-bottom: 10px; }
  .blog-post.blog-overlay.blog-post-05:hover .blog-post-footer span {
    color: #ffffff; }
    .blog-post.blog-overlay.blog-post-05:hover .blog-post-footer span a {
      color: #ffffff; }
  .blog-post.blog-overlay.blog-post-05:hover .blog-post-footer a {
    color: #ffffff; }
    .blog-post.blog-overlay.blog-post-05:hover .blog-post-footer a i {
      color: #ffffff; }
  .blog-post.blog-overlay.blog-post-05:hover .blog-image {
    opacity: 1; }
  .blog-post.blog-overlay.blog-post-05:hover .blog-name h5 {
    color: #ffffff; }

.blog-sidebar .blog-post.blog-overlay.blog-post-05 .blog-image {
  height: 50px; }

.blog-sidebar .blog-post.blog-overlay.blog-post-05 .blog-name {
  padding: 0px; }

/* blog-post-06 */
.blog-post-06 {
  position: relative;
  overflow: hidden; }
  .blog-post-06 .blog-post-image {
    position: relative; }
  .blog-post-06 .like {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ffffff;
    padding-top: 15px;
    line-height: 15px;
    border-radius: 6px;
    content: "";
    width: 60px;
    height: 60px;
    text-align: center; }
  .blog-post-06 .blog-content h6 {
    position: relative;
    padding-left: 55px; }
    .blog-post-06 .blog-content h6:before {
      position: absolute;
      content: "";
      left: 0;
      top: 60%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 40px;
      height: 2px;
      background: #719367; }

.blog-post.blog-post-07 {
  padding: 30px;
  position: relative;
  z-index: 1; }
  .blog-post.blog-post-07:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    background: linear-gradient(0deg, black 5%, rgba(0, 0, 0, 0.09) 100%);
    content: "";
    left: 0;
    right: 0;
    top: 0;
    z-index: 1; }
  .blog-post.blog-post-07 .blog-post-info {
    z-index: 9;
    position: relative; }

/* share-box */
.share-box {
  position: relative;
  z-index: 99; }
  .share-box .share-box-social {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom: 100%;
    background: #719367;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
    transform: translate(-50%, 0px);
    display: block;
    min-width: 150px; }
    .share-box .share-box-social li {
      display: inline-block;
      float: left; }
      .share-box .share-box-social li:last-child a {
        padding-right: 0; }
      .share-box .share-box-social li a {
        padding: 0 8px;
        color: #ffffff !important;
        font-size: 12px;
        display: block; }
        .share-box .share-box-social li a:hover {
          color: #18191c !important; }
          .share-box .share-box-social li a:hover i {
            color: #18191c !important;
            transition: all 0.3s ease-in-out; }
        .share-box .share-box-social li a i {
          color: #ffffff !important;
          transition: all 0.3s ease-in-out;
          padding-right: 5px; }
          .share-box .share-box-social li a i:last-child {
            padding-right: 0; }
    .share-box .share-box-social:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #719367 transparent transparent transparent;
      content: "";
      position: absolute;
      left: 50%;
      bottom: -6px;
      z-index: 99;
      margin-left: -3px; }
  .share-box:hover .share-box-social {
    opacity: 1;
    visibility: visible;
    margin-bottom: 6px; }

/* Blog sidebar */
.blog-sidebar {
  padding: 20px 20px; }
  .blog-sidebar .widget {
    margin-bottom: 40px; }
    .blog-sidebar .widget:last-child {
      margin-bottom: 0; }
    .blog-sidebar .widget .widget-title {
      margin-bottom: 10px;
      font-weight: 800;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px; }
    .blog-sidebar .widget ul.list-style li {
      margin-bottom: 5px; }
      .blog-sidebar .widget ul.list-style li a {
        padding: 12px 10px;
        display: flex;
        color: #000000;
        width: 100%;
        font-size: 16px; }
        .blog-sidebar .widget ul.list-style li a:hover {
          color: #719367; }
        .blog-sidebar .widget ul.list-style li a span {
          font-size: 18px;
          color: #719367; }
      .blog-sidebar .widget ul.list-style li:last-child {
        margin-bottom: 0; }
    .blog-sidebar .widget .gallery ul {
      display: flex;
      flex-wrap: wrap; }
      .blog-sidebar .widget .gallery ul li {
        flex: 0 0 19%;
        margin: 0 1% 1% 0; }
        .blog-sidebar .widget .gallery ul li:nth-child(5n+5) {
          margin-right: 0; }
    .blog-sidebar .widget .social ul li {
      padding: 10px 10px 10px 20px;
      border: 1px solid #e7e7e7;
      display: flex;
      margin-bottom: 10px;
      align-items: center; }
      .blog-sidebar .widget .social ul li a {
        color: #333333;
        font-weight: 600;
        text-transform: capitalize; }
        .blog-sidebar .widget .social ul li a i {
          width: 15px; }
      .blog-sidebar .widget .social ul li .follow {
        background: #f6f6f6;
        padding: 3px 18px;
        font-size: 12px;
        border-radius: 50px; }
        .blog-sidebar .widget .social ul li .follow:hover {
          background: #333333;
          color: #ffffff; }
      .blog-sidebar .widget .social ul li:last-child {
        margin-bottom: 0; }
    .blog-sidebar .widget .social ul .facebook {
      background: #336699; }
      .blog-sidebar .widget .social ul .facebook a {
        color: #ffffff; }
      .blog-sidebar .widget .social ul .facebook .follow {
        background: rgba(255, 255, 255, 0.1); }
        .blog-sidebar .widget .social ul .facebook .follow:hover {
          background: rgba(255, 255, 255, 0.2); }
    .blog-sidebar .widget .social ul .twitter {
      background: #20b5e6; }
      .blog-sidebar .widget .social ul .twitter a {
        color: #ffffff; }
      .blog-sidebar .widget .social ul .twitter .follow {
        background: rgba(255, 255, 255, 0.1); }
        .blog-sidebar .widget .social ul .twitter .follow:hover {
          background: rgba(255, 255, 255, 0.2); }
    .blog-sidebar .widget .social ul .youtube {
      background: #d92c20; }
      .blog-sidebar .widget .social ul .youtube a {
        color: #ffffff; }
      .blog-sidebar .widget .social ul .youtube .follow {
        background: rgba(255, 255, 255, 0.1); }
        .blog-sidebar .widget .social ul .youtube .follow:hover {
          background: rgba(255, 255, 255, 0.2); }
    .blog-sidebar .widget .social ul .linkedIn {
      background: #13799f; }
      .blog-sidebar .widget .social ul .linkedIn a {
        color: #ffffff; }
      .blog-sidebar .widget .social ul .linkedIn .follow {
        background: rgba(255, 255, 255, 0.1); }
        .blog-sidebar .widget .social ul .linkedIn .follow:hover {
          background: rgba(255, 255, 255, 0.2); }
    .blog-sidebar .widget .social ul .instagram {
      background: #cd486b; }
      .blog-sidebar .widget .social ul .instagram a {
        color: #ffffff; }
      .blog-sidebar .widget .social ul .instagram .follow {
        background: rgba(255, 255, 255, 0.1); }
        .blog-sidebar .widget .social ul .instagram .follow:hover {
          background: rgba(255, 255, 255, 0.2); }
    .blog-sidebar .widget .popular-tag ul li {
      display: inline-block;
      margin-bottom: 4px; }
      .blog-sidebar .widget .popular-tag ul li a {
        padding: 6px 20px;
        display: block;
        border: 1px solid #719367;
        color: #719367;
        border-radius: 50px; }
        .blog-sidebar .widget .popular-tag ul li a span {
          display: block; }
        .blog-sidebar .widget .popular-tag ul li a:hover {
          color: #ffffff;
          border-color: #719367;
          background-color: #719367; }
  .blog-sidebar .widget.recent-posts a {
    color: #333333; }
    .blog-sidebar .widget.recent-posts a:hover {
      color: #719367; }

.blog-post-quote {
  position: relative;
  padding: 40px; }
  .blog-post-quote .blog-post-title a:hover {
    color: #333333 !important; }
  .blog-post-quote .blog-post-meta li:before {
    color: #ffffff; }
  .blog-post-quote .post-quote {
    font-size: 60px;
    line-height: 60px;
    color: rgba(255, 255, 255, 0.2);
    text-align: center;
    margin-bottom: 20px; }

.owl-carousel .item .blog-content {
  padding-bottom: 0; }
  .owl-carousel .item .blog-content .badge {
    margin: 15px 0;
    text-transform: uppercase; }
  .owl-carousel .item .blog-content .blog-post-footer {
    padding-bottom: 0; }

/* Blog Dark */
.blog-post-dark .blog-post-content {
  background-color: #000000;
  padding: 0px 30px 30px;
  border: 1px solid rgba(255, 255, 255, 0.1); }

.blog-post-dark .blog-post-content .blog-post-title h4 {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500; }

.blog-post-dark .blog-post-content p {
  color: rgba(255, 255, 255, 0.4); }

.blog-post-dark .blog-post-content .btn-link {
  color: #719367; }

.blog-post-dark .blog-post-content .btn-link:hover {
  color: rgba(255, 255, 255, 0.5); }

.blog-post-dark .blog-post-categorise .blog-post-meta li a {
  color: rgba(255, 255, 255, 0.4); }

.blog-post-dark .blog-post-categorise .blog-post-meta li a:hover {
  color: #719367; }

/* Blog detail */
.blog-detail .blog-post-content {
  text-align: left; }

.navigation .nav-links {
  display: flex; }
  .navigation .nav-links .nav-previous {
    width: 42%;
    margin-right: 3%;
    transition: all 0.5s ease-in-out; }
    .navigation .nav-links .nav-previous a {
      display: flex;
      color: #999999; }
  .navigation .nav-links .nav-next {
    width: 42%;
    margin-left: 3%;
    text-align: right;
    transition: all 0.5s ease-in-out; }
    .navigation .nav-links .nav-next a {
      display: flex;
      color: #999999;
      padding-left: 15px; }
    .navigation .nav-links .nav-next .pagi-text {
      display: flex;
      flex-direction: row;
      justify-content: flex-end; }
    .navigation .nav-links .nav-next .btn i {
      padding-right: 0px;
      padding-left: 10px; }
    .navigation .nav-links .nav-next .nav-title {
      margin: 5px 0px 0px 12px !important; }
  .navigation .nav-links .pagi-text {
    display: inline-block;
    padding: 12px 25px;
    color: #18191c;
    font-size: 16px; }
  .navigation .nav-links .nav-title {
    margin: 5px 20px; }

.owl-carousel.blog-arrow .owl-nav button {
  top: -43px; }
  .owl-carousel.blog-arrow .owl-nav button i {
    width: inherit;
    height: inherit;
    background: inherit;
    color: #333333; }

.owl-carousel.blog-arrow .owl-next {
  right: 0px; }

.owl-carousel.blog-arrow .owl-prev {
  right: 20px;
  left: inherit; }

.blog-post-divider {
  position: relative;
  background: #88b7d5;
  border-bottom: 1px solid #dfdfdf; }

.blog-post-divider:after, .blog-post-divider:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.blog-post-divider:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
  margin-top: -1px; }

.blog-post-divider:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #dfdfdf;
  border-width: 10px;
  margin-left: -10px; }

/* blog-sidebar-post-divider */
.blog-sidebar-post-divider {
  position: relative;
  background: #88b7d5;
  border-bottom: 1px solid #dfdfdf; }

.blog-sidebar-post-divider:after, .blog-sidebar-post-divider:before {
  top: 100%;
  left: 60px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.blog-sidebar-post-divider:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #ffffff;
  border-width: 10px;
  margin-left: -42px;
  margin-top: -1px; }

.light-divider.blog-sidebar-post-divider:after {
  border-top-color: #f6f6f6; }

.blog-sidebar-post-divider:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #dfdfdf;
  border-width: 10px;
  margin-left: -42px; }

.blog-sidebar .widget .social ul li {
  margin-right: 0px; }

/*****************************
  Footer
*****************************/
.footer .footer-useful-List {
  display: flex; }
  .footer .footer-useful-List ul {
    width: 100%;
    display: inline-block; }
    .footer .footer-useful-List ul li {
      margin-bottom: 15px;
      font-size: 16px; }
      .footer .footer-useful-List ul li a {
        color: #ffffff;
        text-transform: uppercase; }

.footer .footer-insta ul li img {
  width: 65px;
  height: auto; }

.footer .footer-tag {
  display: flex;
  flex-wrap: wrap; }
  .footer .footer-tag li {
    margin-right: 3px;
    margin-bottom: 3px;
    display: block; }
    .footer .footer-tag li a {
      background: #000000;
      color: #ffffff;
      padding: 10px 16px;
      display: block; }
      .footer .footer-tag li a span {
        display: block; }
      .footer .footer-tag li a:hover {
        background: #719367;
        color: #89a481; }

.footer .footer-bottom {
  padding: 50px 0px 0px 0px; }
  .footer .footer-bottom .footer-logo img {
    height: 50px; }

.social-round {
  display: flex; }
  .social-round li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: block;
    background: #719367;
    color: #ffffff;
    border-radius: 50%;
    margin: 0 5px;
    font-size: 18px; }
    .social-round li a:hover {
      background: #63815a;
      color: #ffffff; }

.footer-contact-info a span {
  font-size: 12px; }

/* Footer Dark */
.footer-dark {
  background-color: #bbbbbb;
  color: rgba(255, 255, 255, 0.4); }
  .footer-dark h4 {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5); }
  .footer-dark .display-6 {
    font-size: 30px;
    color: #719367; }
  .footer-dark ul li a {
    color: rgba(255, 255, 255, 0.4);
    line-height: 38px; }
    .footer-dark ul li a:hover {
      color: #719367; }
  .footer-dark form .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.3);
    border: none; }

.footer.footer-dark .footer-bottom {
  padding: 20px 0; }

.footer-dark .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4); }

.footer-dark .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4); }

.footer-dark .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4); }

/*************************
       1700px
*************************/
@media (max-width: 1500px) {
  .banner .icon-btn {
    display: none; } }

@media (max-width: 1400px) {
  .navbar .navbar-nav .nav-link {
    padding: 10px 12px; }
  .header .is-sticky.navbar .navbar-nav .nav-link {
    padding: 10px 12px; }
  #slider h1 {
    font-size: 50px;
    line-height: 50px; }
  #slider .carousel-item .slider-content span {
    font-size: 30px;
    line-height: 30px; }
  #slider .btn-link {
    margin-top: 10px; }
  .owl-nav-left.owl-carousel .owl-nav .owl-prev {
    top: 45%; }
  .inner-banner .banner-shape {
    bottom: -29px; } }

@media (max-width: 1199px) {
  .navbar .navbar-nav .nav-link {
    padding: 10px 10px;
    font-size: 14px; }
  .header .is-sticky.navbar .navbar-nav .nav-link {
    padding: 10px 6px; }
  .owl-nav-left.owl-carousel .owl-nav .owl-prev {
    top: 44%; }
  .countdown span {
    font-size: 30px;
    line-height: 40px; }
  .header-transparent .navbar-brand {
    margin-right: 20px; }
  .about-box-border {
    width: 92%; }
  .owl-nav-bottom .owl-nav .owl-prev {
    left: 44%; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav li:hover ul.dropdown-menu {
    width: 8rem; }
  .header .navbar .dropdown-menu a.dropdown-item {
    min-width: 140px; } }

@media (max-width: 991px) {
  .space-ptb {
    padding: 60px 0; }
  .space-pt {
    padding-top: 60px; }
  .space-pb {
    padding-bottom: 60px; }
  .btn {
    padding: 12px 20px; }
  .header .container {
    max-width: 100%; }
  .header .topbar .social {
    margin-right: 0; }
  /*header*/
  .header .container-fluid {
    padding: 0 20px; }
  .navbar-toggler {
    border: none;
    position: absolute;
    height: 75px;
    right: 0; }
  .header .is-sticky.navbar .navbar-toggler {
    right: 20px; }
  .header .header-left-bg {
    display: none; }
  .megamenu {
    margin-left: 0;
    margin-right: 0; }
  .megamenu > li {
    margin-bottom: 30px; }
  .megamenu > li:last-child {
    margin-bottom: 0; }
  .megamenu.dropdown-header {
    padding: 3px 15px !important; }
  .navbar-nav .open .dropdown-menu .dropdown-header {
    color: #fff; }
  .header .navbar-nav > li {
    display: block; }
  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    background: #f6f6f6;
    padding: 14px 30px;
    font-size: 13px;
    max-height: 300px;
    overflow-x: scroll; }
  .header .navbar .dropdown-menu .dropdown-menu {
    background: #ffffff; }
  .header .navbar .dropdown-menu .dropdown-menu .dropdown-menu {
    background: #f6f6f6; }
  .header .add-listing {
    margin-right: 50px; }
  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 9999;
    background: #ffffff;
    width: 100%;
    left: 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); }
  .navbar-brand img {
    height: 34px; }
  .navbar .navbar-nav {
    padding: 10px 0; }
  .navbar .navbar-nav .nav-link {
    padding: 10px 20px; }
  .navbar .navbar-nav .nav-link i {
    margin-left: auto; }
  .header .navbar .nav-title {
    margin-bottom: 10px !important;
    margin-top: 20px; }
  .navbar .dropdown > .dropdown-menu li > a {
    font-size: 13px; }
  .header .navbar-nav > li {
    border-bottom: 1px solid #e7e7e7; }
  .header .navbar-nav > li:last-child {
    border-bottom: 0; }
  .header.default .navbar .navbar-nav .nav-link {
    color: #333333; }
  .header-transparent {
    position: relative;
    background: #333333; }
  .header-transparent .navbar .navbar-nav .nav-link {
    color: #333333; }
  .header .topbar {
    margin-bottom: 0; }
  .header .is-sticky.navbar .navbar-nav .nav-link {
    padding: 10px 20px; }
  .header .navbar-collapse.show ul, .header .navbar-collapse ul {
    padding-left: 0px; }
  .header .navbar-nav .dropdown-menu li > a {
    border: none; }
  .banner .display-4 {
    font-size: 34px; }
  #slider h1 {
    font-size: 30px;
    line-height: 30px; }
  #slider .carousel-item .slider-content span {
    font-size: 20px;
    line-height: 20px; }
  #slider .btn-link {
    margin-top: 10px;
    font-size: 14px; }
  .banner-home .swiper-pagination {
    display: none; }
  /*location*/
  .location-item.location-item-big {
    min-height: 320px; }
  .feature-info {
    padding: 24px; }
  .navigation .nav-links .nav-previous {
    margin-right: 0; }
  .testimonial-03 .testimonial-content p {
    font-size: 16px; }
  .testimonial-03 .testimonial-content .quotes {
    top: 40px; }
  .testimonial-03 .testimonial-content {
    padding: 90px 40px 30px 40px; }
  .testimonial-main {
    padding: 80px 0; }
  .owl-nav-bottom .owl-nav .owl-prev {
    left: 43%; }
  .owl-nav-bottom .owl-nav .owl-next {
    right: 43%; }
  .display-1 {
    font-size: 5.5rem; }
  .display-2 {
    font-size: 5.0rem; }
  .display-3 {
    font-size: 4.0rem; }
  .display-4 {
    font-size: 3.0rem; }
  .about-box-border {
    display: none; }
  .page-title {
    padding: 80px 0; }
  .blog-post .blog-content {
    padding: 0px 0px 40px 0px; }
  .half-overlay-left:before {
    transform: inherit;
    right: -40px;
    top: 0; }
  .half-overlay-right:before {
    transform: inherit; }
  .banner .banner-title {
    font-size: 60px;
    line-height: 60px; }
  .banner-shop {
    height: auto;
    padding: 130px 0; }
  .col-lg-6.half-overlay-right, .col-lg-6.half-overlay-left {
    padding: 30px; }
  .half-overlay-right:before {
    width: 100%;
    left: 0; }
  .half-overlay-left:before {
    width: 100%;
    right: 0; }
  .inner-banner .banner-shape {
    bottom: -36px; }
  .banner-home .swiper-container:before {
    background: none !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 96%; } }

@media (max-width: 767px) {
  h1 {
    font-size: 32px; }
  h2 {
    font-size: 28px; }
  h3 {
    font-size: 24px; }
  h4 {
    font-size: 20px; }
  h5 {
    font-size: 18px; }
  h6 {
    font-size: 17px; }
  .space-ptb {
    padding: 50px 0; }
  .space-pt {
    padding-top: 50px; }
  .space-pb {
    padding-bottom: 50px; }
  .container {
    max-width: 100%; }
  .category ul .category-item a {
    display: block; }
  .category ul .category-item a span {
    padding-left: 0; }
  .header .topbar .dropdown .dropdown-toggle {
    padding: 4px 0;
    display: inline-block; }
  .footer .footer-bottom {
    margin-top: 40px; }
  .testimonial-03 .testimonial-content {
    margin-left: 0; }
  .carousel .carousel-item img {
    height: 230px;
    object-fit: cover; }
  .testimonial {
    padding: 0px 50px; }
  .owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
    display: none; }
  #slider .carousel-item .slider-content span {
    margin-bottom: 10px; }
  .cart-table .actions .coupon input {
    margin-bottom: 15px; }
  .owl-nav-bottom .owl-nav .owl-prev, .owl-nav-bottom .owl-nav .owl-next {
    display: none; }
  .bg-overlay-left:before {
    display: none; }
  .slider-slick {
    overflow: hidden; }
  .banner-yoga .swipeinner {
    padding: 40px 0; }
  .error-404 img {
    width: 150px; }
  .error-404 h1 {
    font-size: 180px;
    line-height: 180px; }
  .inner-banner .banner-shape {
    bottom: -40px; }
  .counter .timer {
    font-size: 40px;
    line-height: 40px; } }

@media (max-width: 575px) {
  h1 {
    font-size: 30px; }
  h2 {
    font-size: 26px; }
  h3 {
    font-size: 24px; }
  .space-ptb {
    padding: 40px 0; }
  .space-pt {
    padding-top: 40px; }
  .space-pb {
    padding-bottom: 40px; }
  .section-title {
    margin-bottom: 20px; }
  .navbar-light .navbar-brand {
    flex: none; }
  .header .add-listing {
    flex: none; }
  .table-skew {
    padding: 0 10px; }
  .cart-table .actions {
    display: block; }
  .banner {
    padding: 60px 0; }
  .banner .lead {
    font-size: 16px; }
  #slider h1 {
    font-size: 16px;
    line-height: 16px; }
  #slider .carousel-item .slider-content span {
    font-size: 14px;
    line-height: 14px; }
  #slider .btn-link {
    display: none; }
  .category ul .category-item {
    max-width: 100%;
    flex: 100%;
    text-align: center; }
  .category ul .category-item a .category-icon {
    margin-right: 0; }
  .location-list ul {
    padding: 0; }
  .banner .banner-shape {
    display: none; }
  .banner .btn {
    padding: 7px 20px; }
  .banner .banner-title {
    font-size: 40px;
    line-height: 40px; }
  .nav-tabs .nav-item .nav-link {
    padding: 20px 10px; }
  .nav-tabs.nav-pills .nav-item .nav-link {
    padding: 10px; }
  .pagination .page-item .page-link {
    padding: 12px 15px; }
  .navigation .nav-links .nav-previous {
    margin-right: 0; }
  .nav-tabs.nav-tabs-02 .nav-item .nav-link {
    padding: 8px 16px; }
  .countdown {
    margin: 0px 10px; }
  .countdown span {
    font-size: 24px;
    line-height: 24px; }
  .countdown p {
    font-size: 14px; }
  .error-404 h1 {
    font-size: 150px;
    line-height: 150px; }
  .owl-nav-top-left .owl-nav {
    bottom: inherit;
    top: inherit;
    margin-top: 10px;
    margin-bottom: 50px; }
  .owl-nav-top-left .owl-nav .owl-next {
    right: inherit;
    top: inherit;
    left: 50%;
    transform: inherit; }
  .owl-nav-top-left .owl-nav .owl-prev {
    left: inherit;
    right: 50%;
    top: inherit;
    transform: inherit;
    margin-right: 1px !important; }
  .owl-nav-top-right .owl-nav {
    bottom: inherit;
    top: inherit;
    margin-top: 10px;
    margin-bottom: 50px; }
  .owl-nav-top-right .owl-nav .owl-next {
    right: inherit;
    top: inherit;
    left: 50%;
    transform: inherit;
    border: 1px solid #e7e7e7; }
  .owl-nav-top-right .owl-nav .owl-prev {
    left: inherit;
    right: 50%;
    top: inherit;
    transform: inherit;
    margin-right: 1px !important;
    border: 1px solid #e7e7e7; }
  .owl-carousel .owl-nav i {
    width: 36px;
    height: 36px;
    font-size: 14px;
    line-height: 36px; }
  .search input {
    padding-right: 120px; }
  .modal.login .modal-header {
    padding: 15px; }
  .modal.login .modal-body {
    padding: 15px; }
  .navigation .nav-links {
    display: block; }
  .navigation .nav-links .nav-previous {
    width: 100%; }
  .navigation .nav-links .nav-next {
    width: 100%;
    margin-top: 15px;
    margin-left: 0px; }
  .share-box-social {
    min-width: auto !important; }
  .accordion-style-2 .card-header .accordion-title i {
    padding-left: 12px; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0; }
  .testimonial-02 .testimonial-content {
    font-size: 16px; }
  .testimonial {
    padding: 0px 30px; }
  .testimonial-dec .h3 {
    font-size: 18px; }
  .display-1 {
    font-size: 4.0rem; }
  .display-2 {
    font-size: 3.5rem; }
  .display-3 {
    font-size: 2.6rem; }
  .display-4 {
    font-size: 2.2rem; }
  .testimonial .testimonial-quote {
    display: none; }
  .blog-post .blog-post-meta {
    margin: 0 10px; }
  .accordion .card {
    padding: 0 16px; }
  .error-404 img {
    width: 90px; }
  .error-404 h1 {
    font-size: 100px;
    line-height: 80px; }
  .inner-banner .banner-shape {
    bottom: -45px; }
  .blog-post.blog-post-04 {
    display: inline-block; }
    .blog-post.blog-post-04 .blog-post-image {
      width: 100%; }
    .blog-post.blog-post-04 .blog-content {
      width: 100%;
      padding: 20px !important; }
  .blog-overlay h1 {
    font-size: 22px; }
  .blog-overlay-02 .blog-name {
    padding: 15px; } }

@media (max-width: 400px) {
  .social-icon-02 ul li a {
    width: 33px;
    height: 33px;
    line-height: 33px; }
  .btn + .btn {
    margin-left: 0; }
  .gm-control-active.gm-fullscreen-control {
    display: none !important; }
  .banner {
    padding: 40px 0; }
  .banner .btn {
    padding: 6px 20px; }
  .btn {
    padding: 10px 12px; }
  .blog-post .blog-post-footer .blog-post-time {
    margin: 0 4px; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .share-box .share-box-social {
    min-width: 170px; }
  .navbar .navbar-nav .nav-item .nav-link {
    padding-top: 27px;
    padding-bottom: 27px; }
  .navbar .navbar-nav .nav-link i {
    margin-top: 5px; } }
