/*****************************
  Newsletter
*****************************/
.newsletter {
	padding:50px 30px;
	position: relative;

	.form-control {
		padding-right: 180px;
		height: 50px;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.btn {
		position: absolute;
		right: 0;
		line-height: 20px;
		top: 0;
		padding: 14px 30px;
	}
	.custom-control-label{
		margin-top: -5px;
    margin-left: 10px;
	}

}
